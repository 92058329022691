import client from "../../client";
import type { ResponseConfig } from "../../client";
import type { PostApiCronjobJobnameMutationResponse, PostApiCronjobJobnamePathParams } from "../models/PostApiCronjobJobname";
import type { DeleteAnnualServiceChargeStatementMutationResponse, DeleteAnnualServiceChargeStatementPathParams } from "../models/annualServiceChargeStatementModels/DeleteAnnualServiceChargeStatement";
import type { FindAnnualServiceChargeStatementsQueryParams, FindAnnualServiceChargeStatementsQueryResponse } from "../models/annualServiceChargeStatementModels/FindAnnualServiceChargeStatements";
import type { SaveAnnualServiceChargeStatementMutationRequest, SaveAnnualServiceChargeStatementMutationResponse } from "../models/annualServiceChargeStatementModels/SaveAnnualServiceChargeStatement";
import type { GetConfigQueryResponse } from "../models/configModels/GetConfig";
import type { GetEntitySettingQueryParams, GetEntitySettingQueryResponse } from "../models/entitySettingModels/GetEntitySetting";
import type { UpsertEntitySettingMutationRequest, UpsertEntitySettingMutationResponse } from "../models/entitySettingModels/UpsertEntitySetting";
import type { GetEstateDetailsPathParams, GetEstateDetailsQueryParams, GetEstateDetailsQueryResponse } from "../models/estateModels/GetEstateDetails";
import type { CreateInvestorAnnualSettlementMutationRequest, CreateInvestorAnnualSettlementMutationResponse } from "../models/investorAnnualSettlementModels/CreateInvestorAnnualSettlement";
import type { DeleteInvestorAnnualSettlementMutationResponse, DeleteInvestorAnnualSettlementPathParams } from "../models/investorAnnualSettlementModels/DeleteInvestorAnnualSettlement";
import type { FindInvestorAnnualSettlementsQueryParams, FindInvestorAnnualSettlementsQueryResponse } from "../models/investorAnnualSettlementModels/FindInvestorAnnualSettlements";
import type { UpdateInvestorAnnualSettlementMutationRequest, UpdateInvestorAnnualSettlementMutationResponse } from "../models/investorAnnualSettlementModels/UpdateInvestorAnnualSettlement";
import type { CreateInvestorInfoMutationRequest, CreateInvestorInfoMutationResponse } from "../models/investorInfoModels/CreateInvestorInfo";
import type { FindInvestorInfosQueryParams, FindInvestorInfosQueryResponse } from "../models/investorInfoModels/FindInvestorInfos";
import type { GetActiveInvestorInfosQueryParams, GetActiveInvestorInfosQueryResponse } from "../models/investorInfoModels/GetActiveInvestorInfos";
import type { GetInvestorDataQueryParams, GetInvestorDataQueryResponse } from "../models/investorInfoModels/GetInvestorData";
import type { GetInvestorInfoPathParams, GetInvestorInfoQueryParams, GetInvestorInfoQueryResponse } from "../models/investorInfoModels/GetInvestorInfo";
import type { GetLiquidityReserveBuildUpSamplePathParams, GetLiquidityReserveBuildUpSampleQueryResponse } from "../models/investorInfoModels/GetLiquidityReserveBuildUpSample";
import type { GetTheoreticalPayoutBreakdownPathParams, GetTheoreticalPayoutBreakdownQueryResponse } from "../models/investorInfoModels/GetTheoreticalPayoutBreakdown";
import type { InitializeInvestorInfoMutationRequest, InitializeInvestorInfoMutationResponse } from "../models/investorInfoModels/InitializeInvestorInfo";
import type { RecalculateRentBalanceForAllInvestorInfosMutationResponse } from "../models/investorInfoModels/RecalculateRentBalanceForAllInvestorInfos";
import type { RecalculateRentBalanceForInvestorInfoMutationResponse, RecalculateRentBalanceForInvestorInfoQueryParams } from "../models/investorInfoModels/RecalculateRentBalanceForInvestorInfo";
import type { UpdateInvestorInfoMutationRequest, UpdateInvestorInfoMutationResponse, UpdateInvestorInfoPathParams, UpdateInvestorInfoQueryParams } from "../models/investorInfoModels/UpdateInvestorInfo";
import type { UpdateInvestorInfoStartDateAndSpecialDealMutationRequest, UpdateInvestorInfoStartDateAndSpecialDealMutationResponse } from "../models/investorInfoModels/UpdateInvestorInfoStartDateAndSpecialDeal";
import type { CreateInvestorYearlyPayoutMutationRequest, CreateInvestorYearlyPayoutMutationResponse } from "../models/investorYearlyPayoutModels/CreateInvestorYearlyPayout";
import type { DeleteInvestorYearlyPayoutMutationResponse, DeleteInvestorYearlyPayoutPathParams } from "../models/investorYearlyPayoutModels/DeleteInvestorYearlyPayout";
import type { FindInvestorYearlyPayoutsQueryParams, FindInvestorYearlyPayoutsQueryResponse } from "../models/investorYearlyPayoutModels/FindInvestorYearlyPayouts";
import type { UpdateInvestorYearlyPayoutMutationRequest, UpdateInvestorYearlyPayoutMutationResponse } from "../models/investorYearlyPayoutModels/UpdateInvestorYearlyPayout";
import type { GetTenantsQueryResponse } from "../models/livingsModels/GetTenants";
import type { UpdateTenantInformationMutationRequest, UpdateTenantInformationMutationResponse } from "../models/livingsModels/UpdateTenantInformation";
import type { ExportMonthlySevPaymentMutationRequest, ExportMonthlySevPaymentMutationResponse, ExportMonthlySevPaymentQueryParams } from "../models/monthlyInvestorSharesModels/ExportMonthlySevPayment";
import type { GetMinMaxDateOfMonthlyInvestorShareQueryResponse } from "../models/monthlyInvestorSharesModels/GetMinMaxDateOfMonthlyInvestorShare";
import type { UpdateMonthlyInvestorShareMutationRequest, UpdateMonthlyInvestorShareMutationResponse, UpdateMonthlyInvestorSharePathParams } from "../models/monthlyInvestorSharesModels/UpdateMonthlyInvestorShare";
import type { UpdateMonthlyInvestorShareOperationsMutationRequest, UpdateMonthlyInvestorShareOperationsMutationResponse, UpdateMonthlyInvestorShareOperationsPathParams } from "../models/monthlyInvestorSharesModels/UpdateMonthlyInvestorShareOperations";
import type { FindMonthlyRentGuaranteesQueryParams, FindMonthlyRentGuaranteesQueryResponse } from "../models/monthlyRentGuaranteeModels/FindMonthlyRentGuarantees";
import type { GetMonthlyRentGuaranteePathParams, GetMonthlyRentGuaranteeQueryParams, GetMonthlyRentGuaranteeQueryResponse } from "../models/monthlyRentGuaranteeModels/GetMonthlyRentGuarantee";
import type { GetMonthlyRentGuaranteeRangeQueryResponse } from "../models/monthlyRentGuaranteeModels/GetMonthlyRentGuaranteeRange";
import type { RentGuaranteeBatchGenerateMutationRequest, RentGuaranteeBatchGenerateMutationResponse } from "../models/monthlyRentGuaranteeModels/RentGuaranteeBatchGenerate";
import type { UpdateMonthlyRentGuaranteeMutationRequest, UpdateMonthlyRentGuaranteeMutationResponse, UpdateMonthlyRentGuaranteePathParams } from "../models/monthlyRentGuaranteeModels/UpdateMonthlyRentGuarantee";
import type { DeleteArrearNoticeMutationResponse, DeleteArrearNoticePathParams } from "../models/rentArrearNoticeModels/DeleteArrearNotice";
import type { GenerateArrearNoticesMutationRequest, GenerateArrearNoticesMutationResponse } from "../models/rentArrearNoticeModels/GenerateArrearNotices";
import type { UpdateArrearNoticeMutationRequest, UpdateArrearNoticeMutationResponse } from "../models/rentArrearNoticeModels/UpdateArrearNotice";
import type { CreateRentMutationRequest, CreateRentMutationResponse, CreateRentPathParams, CreateRentQueryParams } from "../models/rentModels/CreateRent";
import type { DeleteRentMutationResponse, DeleteRentPathParams } from "../models/rentModels/DeleteRent";
import type { GetActiveRentsAmountsQueryResponse } from "../models/rentModels/GetActiveRentsAmounts";
import type { GetRentPathParams, GetRentQueryParams, GetRentQueryResponse } from "../models/rentModels/GetRent";
import type { UpdateRentMutationRequest, UpdateRentMutationResponse, UpdateRentPathParams, UpdateRentQueryParams } from "../models/rentModels/UpdateRent";
import type { UpdateRentBalanceAdjustmentsMutationRequest, UpdateRentBalanceAdjustmentsMutationResponse, UpdateRentBalanceAdjustmentsPathParams } from "../models/rentModels/UpdateRentBalanceAdjustments";
import type { DeleteRentTenancyMutationResponse, DeleteRentTenancyPathParams } from "../models/rentTenancyModels/DeleteRentTenancy";
import type { GetActiveRentTenanciesQueryResponse } from "../models/rentTenancyModels/GetActiveRentTenancies";
import type { GetEstateRentTenanciesPathParams, GetEstateRentTenanciesQueryParams, GetEstateRentTenanciesQueryResponse } from "../models/rentTenancyModels/GetEstateRentTenancies";
import type { GetManyEstateActiveRentTenanciesQueryParams, GetManyEstateActiveRentTenanciesQueryResponse } from "../models/rentTenancyModels/GetManyEstateActiveRentTenancies";
import type { GetRentTenancyPathParams, GetRentTenancyQueryParams, GetRentTenancyQueryResponse } from "../models/rentTenancyModels/GetRentTenancy";
import type { RecalculatePaidDepositMutationResponse, RecalculatePaidDepositPathParams, RecalculatePaidDepositQueryParams } from "../models/rentTenancyModels/RecalculatePaidDeposit";
import type { RecalculateRentBalanceMutationResponse, RecalculateRentBalanceQueryParams } from "../models/rentTenancyModels/RecalculateRentBalance";
import type { SaveRentTenancyMutationRequest, SaveRentTenancyMutationResponse } from "../models/rentTenancyModels/SaveRentTenancy";
import type { SetDepositReturnDateMutationRequest, SetDepositReturnDateMutationResponse, SetDepositReturnDatePathParams } from "../models/rentTenancyModels/SetDepositReturnDate";
import type { UpdateDunningStatusMutationRequest, UpdateDunningStatusMutationResponse, UpdateDunningStatusPathParams } from "../models/rentTenancyModels/UpdateDunningStatus";
import type { UpdateRentTenancyMutationRequest, UpdateRentTenancyMutationResponse, UpdateRentTenancyPathParams } from "../models/rentTenancyModels/UpdateRentTenancy";
import type { CreateEstateRentableUnitMutationRequest, CreateEstateRentableUnitMutationResponse } from "../models/rentableUnitModels/CreateEstateRentableUnit";
import type { CreateParkingRentableUnitMutationRequest, CreateParkingRentableUnitMutationResponse } from "../models/rentableUnitModels/CreateParkingRentableUnit";
import type { CreateRoomRentableUnitMutationRequest, CreateRoomRentableUnitMutationResponse } from "../models/rentableUnitModels/CreateRoomRentableUnit";
import type { DeleteEstateRentableUnitMutationResponse, DeleteEstateRentableUnitPathParams } from "../models/rentableUnitModels/DeleteEstateRentableUnit";
import type { DeleteParkingRentableUnitMutationResponse, DeleteParkingRentableUnitPathParams } from "../models/rentableUnitModels/DeleteParkingRentableUnit";
import type { DeleteRentableUnitByIdMutationResponse, DeleteRentableUnitByIdPathParams } from "../models/rentableUnitModels/DeleteRentableUnitById";
import type { DeleteRoomRentableUnitMutationResponse, DeleteRoomRentableUnitPathParams } from "../models/rentableUnitModels/DeleteRoomRentableUnit";
import type { FindRentableUnitsQueryParams, FindRentableUnitsQueryResponse } from "../models/rentableUnitModels/FindRentableUnits";
import type { GetRentableUnitQueryParams, GetRentableUnitQueryResponse } from "../models/rentableUnitModels/GetRentableUnit";
import type { GetRentableUnitByIdPathParams, GetRentableUnitByIdQueryResponse } from "../models/rentableUnitModels/GetRentableUnitById";
import type { UpdateRentableUnitMutationRequest, UpdateRentableUnitMutationResponse } from "../models/rentableUnitModels/UpdateRentableUnit";
import type { CreateRentsFromRentalContractMutationRequest, CreateRentsFromRentalContractMutationResponse } from "../models/rentalContractsModels/CreateRentsFromRentalContract";
import type { FindRenalContractsQueryParams, FindRenalContractsQueryResponse } from "../models/rentalContractsModels/FindRenalContracts";
import type { GetRenalContractPathParams, GetRenalContractQueryResponse } from "../models/rentalContractsModels/GetRenalContract";
import type { UpsertRentalContractMutationRequest, UpsertRentalContractMutationResponse } from "../models/rentalContractsModels/UpsertRentalContract";
import type { DeleteRentingLeadByIdMutationResponse, DeleteRentingLeadByIdPathParams } from "../models/rentingLeadModels/DeleteRentingLeadById";
import type { FindRentingLeadsQueryParams, FindRentingLeadsQueryResponse } from "../models/rentingLeadModels/FindRentingLeads";
import type { GetRentingLeadByIdPathParams, GetRentingLeadByIdQueryResponse } from "../models/rentingLeadModels/GetRentingLeadById";
import type { SaveRentingLeadMutationRequest, SaveRentingLeadMutationResponse } from "../models/rentingLeadModels/SaveRentingLead";
import type { AddAttachmentsMutationRequest, AddAttachmentsMutationResponse, AddAttachmentsPathParams } from "../models/rentingListingModels/AddAttachments";
import type { CreateRentingListingMutationRequest, CreateRentingListingMutationResponse } from "../models/rentingListingModels/CreateRentingListing";
import type { DeleteRentingListingMutationResponse, DeleteRentingListingPathParams } from "../models/rentingListingModels/DeleteRentingListing";
import type { DeleteRentingListingAttachmentMutationResponse, DeleteRentingListingAttachmentPathParams } from "../models/rentingListingModels/DeleteRentingListingAttachment";
import type { DeleteUnpublishedRentingListingMutationResponse, DeleteUnpublishedRentingListingPathParams } from "../models/rentingListingModels/DeleteUnpublishedRentingListing";
import type { ExportRentingListingAsOpenImmoPathParams, ExportRentingListingAsOpenImmoQueryResponse } from "../models/rentingListingModels/ExportRentingListingAsOpenImmo";
import type { GetRentingDetailsOfEstatePathParams, GetRentingDetailsOfEstateQueryResponse } from "../models/rentingListingModels/GetRentingDetailsOfEstate";
import type { GetRentingListingQueryParams, GetRentingListingQueryResponse } from "../models/rentingListingModels/GetRentingListing";
import type { GetRentingListingAttachmentsPathParams, GetRentingListingAttachmentsQueryResponse } from "../models/rentingListingModels/GetRentingListingAttachments";
import type { GetRentingListingAttachmentsOrderPathParams, GetRentingListingAttachmentsOrderQueryResponse } from "../models/rentingListingModels/GetRentingListingAttachmentsOrder";
import type { InvokeRentingListingActionMutationResponse, InvokeRentingListingActionPathParams } from "../models/rentingListingModels/InvokeRentingListingAction";
import type { UpdateRentingListingMutationRequest, UpdateRentingListingMutationResponse, UpdateRentingListingPathParams } from "../models/rentingListingModels/UpdateRentingListing";
import type { UpdateRentingListingAttachmentMutationRequest, UpdateRentingListingAttachmentMutationResponse, UpdateRentingListingAttachmentPathParams } from "../models/rentingListingModels/UpdateRentingListingAttachment";
import type { UpdateRentingListingAttachmentsOrderMutationRequest, UpdateRentingListingAttachmentsOrderMutationResponse, UpdateRentingListingAttachmentsOrderPathParams } from "../models/rentingListingModels/UpdateRentingListingAttachmentsOrder";
import type { StatsQueryParams, StatsQueryResponse } from "../models/statsModels/Stats";
import type { GetPortalInvitationProgressQueryParams, GetPortalInvitationProgressQueryResponse } from "../models/userInfoModels/GetPortalInvitationProgress";
import type { GetRegisteredTenantsQueryResponse } from "../models/userInfoModels/GetRegisteredTenants";
import type { SendContactInvitationMutationRequest, SendContactInvitationMutationResponse, SendContactInvitationPathParams } from "../models/userInfoModels/SendContactInvitation";
import type { UpdateContactEmailMutationRequest, UpdateContactEmailMutationResponse, UpdateContactEmailPathParams } from "../models/userInfoModels/UpdateContactEmail";

 /**
 * @description Gets the default runtime config for propx frontend
 * @link /config
 */
export async function getConfig(options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetConfigQueryResponse>["data"]> {
    const res = await client<GetConfigQueryResponse>({ method: "get", url: `/config`, ...options });
    return res.data;
}

 /**
 * @description Create or update renting lead
 * @link /api/renting-lead
 */
export async function saveRentingLead(data: SaveRentingLeadMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<SaveRentingLeadMutationResponse>["data"]> {
    const res = await client<SaveRentingLeadMutationResponse, SaveRentingLeadMutationRequest>({ method: "post", url: `/api/renting-lead`, data, ...options });
    return res.data;
}

 /**
 * @description Get renting lead by id
 * @link /api/renting-lead/:rentingLeadId
 */
export async function getRentingLeadById({ rentingLeadId }: {
    rentingLeadId: GetRentingLeadByIdPathParams["rentingLeadId"];
}, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetRentingLeadByIdQueryResponse>["data"]> {
    const res = await client<GetRentingLeadByIdQueryResponse>({ method: "get", url: `/api/renting-lead/${rentingLeadId}`, ...options });
    return res.data;
}

 /**
 * @description Delete renting lead by id
 * @link /api/renting-lead/:rentingLeadId
 */
export async function deleteRentingLeadById({ rentingLeadId }: {
    rentingLeadId: DeleteRentingLeadByIdPathParams["rentingLeadId"];
}, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<DeleteRentingLeadByIdMutationResponse>["data"]> {
    const res = await client<DeleteRentingLeadByIdMutationResponse>({ method: "delete", url: `/api/renting-lead/${rentingLeadId}`, ...options });
    return res.data;
}

 /**
 * @description Get list of renting leads
 * @link /api/renting-lead/list
 */
export async function findRentingLeads(params?: FindRentingLeadsQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<FindRentingLeadsQueryResponse>["data"]> {
    const res = await client<FindRentingLeadsQueryResponse>({ method: "get", url: `/api/renting-lead/list`, params, ...options });
    return res.data;
}

 /**
 * @description Get renting listing
 * @link /api/renting-listing
 */
export async function getRentingListing(params: GetRentingListingQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetRentingListingQueryResponse>["data"]> {
    const res = await client<GetRentingListingQueryResponse>({ method: "get", url: `/api/renting-listing`, params, ...options });
    return res.data;
}

 /**
 * @description Create renting listing
 * @link /api/renting-listing
 */
export async function createRentingListing(data: CreateRentingListingMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<CreateRentingListingMutationResponse>["data"]> {
    const res = await client<CreateRentingListingMutationResponse, CreateRentingListingMutationRequest>({ method: "post", url: `/api/renting-listing`, data, ...options });
    return res.data;
}

 /**
 * @description Update renting listing
 * @link /api/renting-listing/:rentingListingId
 */
export async function updateRentingListing({ rentingListingId }: {
    rentingListingId: UpdateRentingListingPathParams["rentingListingId"];
}, data: UpdateRentingListingMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<UpdateRentingListingMutationResponse>["data"]> {
    const res = await client<UpdateRentingListingMutationResponse, UpdateRentingListingMutationRequest>({ method: "patch", url: `/api/renting-listing/${rentingListingId}`, data, ...options });
    return res.data;
}

 /**
 * @description Delete unpublished renting listing
 * @link /api/renting-listing/:rentingListingId
 */
export async function deleteUnpublishedRentingListing({ rentingListingId }: {
    rentingListingId: DeleteUnpublishedRentingListingPathParams["rentingListingId"];
}, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<DeleteUnpublishedRentingListingMutationResponse>["data"]> {
    const res = await client<DeleteUnpublishedRentingListingMutationResponse>({ method: "delete", url: `/api/renting-listing/${rentingListingId}`, ...options });
    return res.data;
}

 /**
 * @description Delete renting listing
 * @link /api/renting-listing/:rentingListingId/provider/:provider
 */
export async function deleteRentingListing({ rentingListingId, provider }: {
    rentingListingId: DeleteRentingListingPathParams["rentingListingId"];
    provider: DeleteRentingListingPathParams["provider"];
}, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<DeleteRentingListingMutationResponse>["data"]> {
    const res = await client<DeleteRentingListingMutationResponse>({ method: "delete", url: `/api/renting-listing/${rentingListingId}/provider/${provider}`, ...options });
    return res.data;
}

 /**
 * @description Pre-publish/publish/un-publish renting listing
 * @link /api/renting-listing/:rentingListingId/providers/:provider/actions/:action
 */
export async function invokeRentingListingAction({ rentingListingId, provider, action }: {
    rentingListingId: InvokeRentingListingActionPathParams["rentingListingId"];
    provider: InvokeRentingListingActionPathParams["provider"];
    action: InvokeRentingListingActionPathParams["action"];
}, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<InvokeRentingListingActionMutationResponse>["data"]> {
    const res = await client<InvokeRentingListingActionMutationResponse>({ method: "post", url: `/api/renting-listing/${rentingListingId}/providers/${provider}/actions/${action}`, ...options });
    return res.data;
}

 /**
 * @description Add attachments to renting listing
 * @link /api/renting-listing/:rentingLeadId/attachments
 */
export async function addAttachments({ rentingLeadId }: {
    rentingLeadId: AddAttachmentsPathParams["rentingLeadId"];
}, data: AddAttachmentsMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<AddAttachmentsMutationResponse>["data"]> {
    const res = await client<AddAttachmentsMutationResponse, AddAttachmentsMutationRequest>({ method: "post", url: `/api/renting-listing/${rentingLeadId}/attachments`, data, ...options });
    return res.data;
}

 /**
 * @description Get renting listing attachments
 * @link /api/renting-listing/:rentingListingId/attachments
 */
export async function getRentingListingAttachments({ rentingListingId }: {
    rentingListingId: GetRentingListingAttachmentsPathParams["rentingListingId"];
}, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetRentingListingAttachmentsQueryResponse>["data"]> {
    const res = await client<GetRentingListingAttachmentsQueryResponse>({ method: "get", url: `/api/renting-listing/${rentingListingId}/attachments`, ...options });
    return res.data;
}

 /**
 * @description Get renting listing attachments order
 * @link /api/renting-listing/:rentingListingId/attachments-order
 */
export async function getRentingListingAttachmentsOrder({ rentingListingId }: {
    rentingListingId: GetRentingListingAttachmentsOrderPathParams["rentingListingId"];
}, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetRentingListingAttachmentsOrderQueryResponse>["data"]> {
    const res = await client<GetRentingListingAttachmentsOrderQueryResponse>({ method: "get", url: `/api/renting-listing/${rentingListingId}/attachments-order`, ...options });
    return res.data;
}

 /**
 * @description Update renting listing attachments order
 * @link /api/renting-listing/:rentingListingId/attachments-order
 */
export async function updateRentingListingAttachmentsOrder({ rentingListingId }: {
    rentingListingId: UpdateRentingListingAttachmentsOrderPathParams["rentingListingId"];
}, data?: UpdateRentingListingAttachmentsOrderMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<UpdateRentingListingAttachmentsOrderMutationResponse>["data"]> {
    const res = await client<UpdateRentingListingAttachmentsOrderMutationResponse, UpdateRentingListingAttachmentsOrderMutationRequest>({ method: "put", url: `/api/renting-listing/${rentingListingId}/attachments-order`, data, ...options });
    return res.data;
}

 /**
 * @description Update renting listing attachment
 * @link /api/renting-listing/:rentingListingId/attachments/:attachmentId
 */
export async function updateRentingListingAttachment({ rentingListingId, attachmentId }: {
    rentingListingId: UpdateRentingListingAttachmentPathParams["rentingListingId"];
    attachmentId: UpdateRentingListingAttachmentPathParams["attachmentId"];
}, data: UpdateRentingListingAttachmentMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<UpdateRentingListingAttachmentMutationResponse>["data"]> {
    const res = await client<UpdateRentingListingAttachmentMutationResponse, UpdateRentingListingAttachmentMutationRequest>({ method: "put", url: `/api/renting-listing/${rentingListingId}/attachments/${attachmentId}`, data, ...options });
    return res.data;
}

 /**
 * @description Delete renting listing attachment
 * @link /api/renting-listing/:rentingListingId/attachments/:attachmentId
 */
export async function deleteRentingListingAttachment({ rentingListingId, attachmentId }: {
    rentingListingId: DeleteRentingListingAttachmentPathParams["rentingListingId"];
    attachmentId: DeleteRentingListingAttachmentPathParams["attachmentId"];
}, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<DeleteRentingListingAttachmentMutationResponse>["data"]> {
    const res = await client<DeleteRentingListingAttachmentMutationResponse>({ method: "delete", url: `/api/renting-listing/${rentingListingId}/attachments/${attachmentId}`, ...options });
    return res.data;
}

 /**
 * @description Export renting listing as open immo
 * @link /api/renting-listing/:rentingListingId/export
 */
export async function exportRentingListingAsOpenImmo({ rentingListingId }: {
    rentingListingId: ExportRentingListingAsOpenImmoPathParams["rentingListingId"];
}, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<ExportRentingListingAsOpenImmoQueryResponse>["data"]> {
    const res = await client<ExportRentingListingAsOpenImmoQueryResponse>({ method: "get", url: `/api/renting-listing/${rentingListingId}/export`, ...options });
    return res.data;
}

 /**
 * @description Get renting details of estate
 * @link /api/renting/estate/:estateId
 */
export async function getRentingDetailsOfEstate({ estateId }: {
    estateId: GetRentingDetailsOfEstatePathParams["estateId"];
}, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetRentingDetailsOfEstateQueryResponse>["data"]> {
    const res = await client<GetRentingDetailsOfEstateQueryResponse>({ method: "get", url: `/api/renting/estate/${estateId}`, ...options });
    return res.data;
}

 /**
 * @description Create rentable unit
 * @link /api/estate-rentable-unit
 */
export async function createEstateRentableUnit(data: CreateEstateRentableUnitMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<CreateEstateRentableUnitMutationResponse>["data"]> {
    const res = await client<CreateEstateRentableUnitMutationResponse, CreateEstateRentableUnitMutationRequest>({ method: "post", url: `/api/estate-rentable-unit`, data, ...options });
    return res.data;
}

 /**
 * @description Create rentable unit
 * @link /api/room-rentable-unit
 */
export async function createRoomRentableUnit(data: CreateRoomRentableUnitMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<CreateRoomRentableUnitMutationResponse>["data"]> {
    const res = await client<CreateRoomRentableUnitMutationResponse, CreateRoomRentableUnitMutationRequest>({ method: "post", url: `/api/room-rentable-unit`, data, ...options });
    return res.data;
}

 /**
 * @description Create rentable unit
 * @link /api/parking-rentable-unit
 */
export async function createParkingRentableUnit(data: CreateParkingRentableUnitMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<CreateParkingRentableUnitMutationResponse>["data"]> {
    const res = await client<CreateParkingRentableUnitMutationResponse, CreateParkingRentableUnitMutationRequest>({ method: "post", url: `/api/parking-rentable-unit`, data, ...options });
    return res.data;
}

 /**
 * @description Update a rentable unit
 * @link /api/rentable-unit
 */
export async function updateRentableUnit(data: UpdateRentableUnitMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<UpdateRentableUnitMutationResponse>["data"]> {
    const res = await client<UpdateRentableUnitMutationResponse, UpdateRentableUnitMutationRequest>({ method: "put", url: `/api/rentable-unit`, data, ...options });
    return res.data;
}

 /**
 * @description Find rentable units
 * @link /api/rentable-unit
 */
export async function findRentableUnits(params: FindRentableUnitsQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<FindRentableUnitsQueryResponse>["data"]> {
    const res = await client<FindRentableUnitsQueryResponse>({ method: "get", url: `/api/rentable-unit`, params, ...options });
    return res.data;
}

 /**
 * @description Get rentable unit by id
 * @link /api/rentable-unit/:rentableUnitId
 */
export async function getRentableUnitById({ rentableUnitId }: {
    rentableUnitId: GetRentableUnitByIdPathParams["rentableUnitId"];
}, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetRentableUnitByIdQueryResponse>["data"]> {
    const res = await client<GetRentableUnitByIdQueryResponse>({ method: "get", url: `/api/rentable-unit/${rentableUnitId}`, ...options });
    return res.data;
}

 /**
 * @description Delete rentable unit by id
 * @link /api/rentable-unit/:rentableUnitId
 */
export async function deleteRentableUnitById({ rentableUnitId }: {
    rentableUnitId: DeleteRentableUnitByIdPathParams["rentableUnitId"];
}, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<DeleteRentableUnitByIdMutationResponse>["data"]> {
    const res = await client<DeleteRentableUnitByIdMutationResponse>({ method: "delete", url: `/api/rentable-unit/${rentableUnitId}`, ...options });
    return res.data;
}

 /**
 * @description Get rentable unit by one key. If more than one key is present priority follows: rentableUnitId, estateId, estateElementId, parkingSpaceId
 * @link /api/rentable-unit/one
 */
export async function getRentableUnit(params?: GetRentableUnitQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetRentableUnitQueryResponse>["data"]> {
    const res = await client<GetRentableUnitQueryResponse>({ method: "get", url: `/api/rentable-unit/one`, params, ...options });
    return res.data;
}

 /**
 * @description Delete estate rentable unit by estateId
 * @link /api/estate-rentable-unit/:estateId
 */
export async function deleteEstateRentableUnit({ estateId }: {
    estateId: DeleteEstateRentableUnitPathParams["estateId"];
}, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<DeleteEstateRentableUnitMutationResponse>["data"]> {
    const res = await client<DeleteEstateRentableUnitMutationResponse>({ method: "delete", url: `/api/estate-rentable-unit/${estateId}`, ...options });
    return res.data;
}

 /**
 * @description Delete room rentable unit by estateElementId
 * @link /api/room-rentable-unit/:estateElementId
 */
export async function deleteRoomRentableUnit({ estateElementId }: {
    estateElementId: DeleteRoomRentableUnitPathParams["estateElementId"];
}, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<DeleteRoomRentableUnitMutationResponse>["data"]> {
    const res = await client<DeleteRoomRentableUnitMutationResponse>({ method: "delete", url: `/api/room-rentable-unit/${estateElementId}`, ...options });
    return res.data;
}

 /**
 * @description Delete parking rentable unit by parkingSpaceId
 * @link /api/parking-rentable-unit/:parkingSpaceId
 */
export async function deleteParkingRentableUnit({ parkingSpaceId }: {
    parkingSpaceId: DeleteParkingRentableUnitPathParams["parkingSpaceId"];
}, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<DeleteParkingRentableUnitMutationResponse>["data"]> {
    const res = await client<DeleteParkingRentableUnitMutationResponse>({ method: "delete", url: `/api/parking-rentable-unit/${parkingSpaceId}`, ...options });
    return res.data;
}

 /**
 * @description Get entity setting by entity ID
 * @link /api/entity-setting
 */
export async function getEntitySetting(params?: GetEntitySettingQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetEntitySettingQueryResponse>["data"]> {
    const res = await client<GetEntitySettingQueryResponse>({ method: "get", url: `/api/entity-setting`, params, ...options });
    return res.data;
}

 /**
 * @description Upsert entity setting
 * @link /api/entity-setting
 */
export async function upsertEntitySetting(data: UpsertEntitySettingMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<UpsertEntitySettingMutationResponse>["data"]> {
    const res = await client<UpsertEntitySettingMutationResponse, UpsertEntitySettingMutationRequest>({ method: "post", url: `/api/entity-setting`, data, ...options });
    return res.data;
}

 /**
 * @description Get estate details for investor portal
 * @link /api/estate/:estateId/details
 */
export async function getEstateDetails({ estateId }: {
    estateId: GetEstateDetailsPathParams["estateId"];
}, params?: GetEstateDetailsQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetEstateDetailsQueryResponse>["data"]> {
    const res = await client<GetEstateDetailsQueryResponse>({ method: "get", url: `/api/estate/${estateId}/details`, params, ...options });
    return res.data;
}

 /**
 * @description Find investor infos
 * @link /api/investor-infos
 */
export async function findInvestorInfos(params?: FindInvestorInfosQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<FindInvestorInfosQueryResponse>["data"]> {
    const res = await client<FindInvestorInfosQueryResponse>({ method: "get", url: `/api/investor-infos`, params, ...options });
    return res.data;
}

 /**
 * @description Create investor info
 * @link /api/investor-infos
 */
export async function createInvestorInfo(data: CreateInvestorInfoMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<CreateInvestorInfoMutationResponse>["data"]> {
    const res = await client<CreateInvestorInfoMutationResponse, CreateInvestorInfoMutationRequest>({ method: "post", url: `/api/investor-infos`, data, ...options });
    return res.data;
}

 /**
 * @description Get all active investor info data
 * @link /api/investor-infos/active
 */
export async function getActiveInvestorInfos(params?: GetActiveInvestorInfosQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetActiveInvestorInfosQueryResponse>["data"]> {
    const res = await client<GetActiveInvestorInfosQueryResponse>({ method: "get", url: `/api/investor-infos/active`, params, ...options });
    return res.data;
}

 /**
 * @description Get investor info data by entity ID
 * @link /api/investor-infos/:investorInfoId
 */
export async function getInvestorInfo({ investorInfoId }: {
    investorInfoId: GetInvestorInfoPathParams["investorInfoId"];
}, params?: GetInvestorInfoQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetInvestorInfoQueryResponse>["data"]> {
    const res = await client<GetInvestorInfoQueryResponse>({ method: "get", url: `/api/investor-infos/${investorInfoId}`, params, ...options });
    return res.data;
}

 /**
 * @description Update investor info
 * @link /api/investor-infos/:investorInfoId
 */
export async function updateInvestorInfo({ investorInfoId }: {
    investorInfoId: UpdateInvestorInfoPathParams["investorInfoId"];
}, data: UpdateInvestorInfoMutationRequest, params?: UpdateInvestorInfoQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<UpdateInvestorInfoMutationResponse>["data"]> {
    const res = await client<UpdateInvestorInfoMutationResponse, UpdateInvestorInfoMutationRequest>({ method: "put", url: `/api/investor-infos/${investorInfoId}`, params, data, ...options });
    return res.data;
}

 /**
 * @description Initialize investor info
 * @link /api/investor-infos/initialize
 */
export async function initializeInvestorInfo(data: InitializeInvestorInfoMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<InitializeInvestorInfoMutationResponse>["data"]> {
    const res = await client<InitializeInvestorInfoMutationResponse, InitializeInvestorInfoMutationRequest>({ method: "post", url: `/api/investor-infos/initialize`, data, ...options });
    return res.data;
}

 /**
 * @description Get theoretical monthly investor shares breakdown
 * @link /api/investor-infos/:investorInfoId/theoretical-payout-breakdown
 */
export async function getTheoreticalPayoutBreakdown({ investorInfoId }: {
    investorInfoId: GetTheoreticalPayoutBreakdownPathParams["investorInfoId"];
}, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetTheoreticalPayoutBreakdownQueryResponse>["data"]> {
    const res = await client<GetTheoreticalPayoutBreakdownQueryResponse>({ method: "get", url: `/api/investor-infos/${investorInfoId}/theoretical-payout-breakdown`, ...options });
    return res.data;
}

 /**
 * @description Get liquidity reserve build up sample
 * @link /api/investor-infos/:investorInfoId/liquidity-reserve-sample
 */
export async function getLiquidityReserveBuildUpSample({ investorInfoId }: {
    investorInfoId: GetLiquidityReserveBuildUpSamplePathParams["investorInfoId"];
}, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetLiquidityReserveBuildUpSampleQueryResponse>["data"]> {
    const res = await client<GetLiquidityReserveBuildUpSampleQueryResponse>({ method: "get", url: `/api/investor-infos/${investorInfoId}/liquidity-reserve-sample`, ...options });
    return res.data;
}

 /**
 * @description Update investor info start date and special deal
 * @link /api/investor-infos/start-date-and-special-deal
 */
export async function updateInvestorInfoStartDateAndSpecialDeal(data: UpdateInvestorInfoStartDateAndSpecialDealMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<UpdateInvestorInfoStartDateAndSpecialDealMutationResponse>["data"]> {
    const res = await client<UpdateInvestorInfoStartDateAndSpecialDealMutationResponse, UpdateInvestorInfoStartDateAndSpecialDealMutationRequest>({ method: "patch", url: `/api/investor-infos/start-date-and-special-deal`, data, ...options });
    return res.data;
}

 /**
 * @description Recalculate rent balance for investor info
 * @link /api/investor-infos/re-calculate-rent-balance
 */
export async function recalculateRentBalanceForInvestorInfo(params?: RecalculateRentBalanceForInvestorInfoQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<RecalculateRentBalanceForInvestorInfoMutationResponse>["data"]> {
    const res = await client<RecalculateRentBalanceForInvestorInfoMutationResponse>({ method: "post", url: `/api/investor-infos/re-calculate-rent-balance`, params, ...options });
    return res.data;
}

 /**
 * @description Recalculate rent balance for all investor infos
 * @link /api/investor-infos/re-calculate-rent-balance/all
 */
export async function recalculateRentBalanceForAllInvestorInfos(options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<RecalculateRentBalanceForAllInvestorInfosMutationResponse>["data"]> {
    const res = await client<RecalculateRentBalanceForAllInvestorInfosMutationResponse>({ method: "post", url: `/api/investor-infos/re-calculate-rent-balance/all`, ...options });
    return res.data;
}

 /**
 * @description Get investor data for investor portal
 * @link /api/investor-data
 */
export async function getInvestorData(params: GetInvestorDataQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetInvestorDataQueryResponse>["data"]> {
    const res = await client<GetInvestorDataQueryResponse>({ method: "get", url: `/api/investor-data`, params, ...options });
    return res.data;
}

 /**
 * @description Get active rents amounts
 * @link /api/rents/active-amounts
 */
export async function getActiveRentsAmounts(options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetActiveRentsAmountsQueryResponse>["data"]> {
    const res = await client<GetActiveRentsAmountsQueryResponse>({ method: "get", url: `/api/rents/active-amounts`, ...options });
    return res.data;
}

 /**
 * @description Get rent by ID
 * @link /api/rents/:rentId
 */
export async function getRent({ rentId }: {
    rentId: GetRentPathParams["rentId"];
}, params?: GetRentQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetRentQueryResponse>["data"]> {
    const res = await client<GetRentQueryResponse>({ method: "get", url: `/api/rents/${rentId}`, params, ...options });
    return res.data;
}

 /**
 * @description Create rent
 * @link /api/rent-tenancies/:rentTenancyId/rents
 */
export async function createRent({ rentTenancyId }: {
    rentTenancyId: CreateRentPathParams["rentTenancyId"];
}, data?: CreateRentMutationRequest, params?: CreateRentQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<CreateRentMutationResponse>["data"]> {
    const res = await client<CreateRentMutationResponse, CreateRentMutationRequest>({ method: "post", url: `/api/rent-tenancies/${rentTenancyId}/rents`, params, data, ...options });
    return res.data;
}

 /**
 * @description Update rent
 * @link /api/rent-tenancies/:rentTenancyId/rents/:rentId
 */
export async function updateRent({ rentTenancyId, rentId }: {
    rentTenancyId: UpdateRentPathParams["rentTenancyId"];
    rentId: UpdateRentPathParams["rentId"];
}, data?: UpdateRentMutationRequest, params?: UpdateRentQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<UpdateRentMutationResponse>["data"]> {
    const res = await client<UpdateRentMutationResponse, UpdateRentMutationRequest>({ method: "put", url: `/api/rent-tenancies/${rentTenancyId}/rents/${rentId}`, params, data, ...options });
    return res.data;
}

 /**
 * @description Delete rent by ID
 * @link /api/rent-tenancies/:rentTenancyId/rents/:rentId
 */
export async function deleteRent({ rentId, rentTenancyId }: {
    rentId: DeleteRentPathParams["rentId"];
    rentTenancyId: DeleteRentPathParams["rentTenancyId"];
}, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<DeleteRentMutationResponse>["data"]> {
    const res = await client<DeleteRentMutationResponse>({ method: "delete", url: `/api/rent-tenancies/${rentTenancyId}/rents/${rentId}`, ...options });
    return res.data;
}

 /**
 * @description Update rent balance adjustments
 * @link /api/rents/:rentId/balance-adjustments
 */
export async function updateRentBalanceAdjustments({ rentId }: {
    rentId: UpdateRentBalanceAdjustmentsPathParams["rentId"];
}, data: UpdateRentBalanceAdjustmentsMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<UpdateRentBalanceAdjustmentsMutationResponse>["data"]> {
    const res = await client<UpdateRentBalanceAdjustmentsMutationResponse, UpdateRentBalanceAdjustmentsMutationRequest>({ method: "put", url: `/api/rents/${rentId}/balance-adjustments`, data, ...options });
    return res.data;
}

 /**
 * @description Get many estates active rent tenancies
 * @link /api/rent-tenancies/estates
 */
export async function getManyEstateActiveRentTenancies(params: GetManyEstateActiveRentTenanciesQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetManyEstateActiveRentTenanciesQueryResponse>["data"]> {
    const res = await client<GetManyEstateActiveRentTenanciesQueryResponse>({ method: "get", url: `/api/rent-tenancies/estates`, params, ...options });
    return res.data;
}

 /**
 * @description Get currently active rent tenancies
 * @link /api/rent-tenancies/active
 */
export async function getActiveRentTenancies(options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetActiveRentTenanciesQueryResponse>["data"]> {
    const res = await client<GetActiveRentTenanciesQueryResponse>({ method: "get", url: `/api/rent-tenancies/active`, ...options });
    return res.data;
}

 /**
 * @description Get rent tenancy by entity ID
 * @link /api/rent-tenancies/:rentTenancyId
 */
export async function getRentTenancy({ rentTenancyId }: {
    rentTenancyId: GetRentTenancyPathParams["rentTenancyId"];
}, params?: GetRentTenancyQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetRentTenancyQueryResponse>["data"]> {
    const res = await client<GetRentTenancyQueryResponse>({ method: "get", url: `/api/rent-tenancies/${rentTenancyId}`, params, ...options });
    return res.data;
}

 /**
 * @description Delete rent tenancy by entity ID
 * @link /api/rent-tenancies/:rentTenancyId
 */
export async function deleteRentTenancy({ rentTenancyId }: {
    rentTenancyId: DeleteRentTenancyPathParams["rentTenancyId"];
}, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<DeleteRentTenancyMutationResponse>["data"]> {
    const res = await client<DeleteRentTenancyMutationResponse>({ method: "delete", url: `/api/rent-tenancies/${rentTenancyId}`, ...options });
    return res.data;
}

 /**
 * @description Update rent tenancy
 * @link /api/rent-tenancies/:rentTenancyId
 */
export async function updateRentTenancy({ rentTenancyId }: {
    rentTenancyId: UpdateRentTenancyPathParams["rentTenancyId"];
}, data?: UpdateRentTenancyMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<UpdateRentTenancyMutationResponse>["data"]> {
    const res = await client<UpdateRentTenancyMutationResponse, UpdateRentTenancyMutationRequest>({ method: "put", url: `/api/rent-tenancies/${rentTenancyId}`, data, ...options });
    return res.data;
}

 /**
 * @description Get estate rent tenancies
 * @link /api/rent-tenancies/estate/:estateId
 */
export async function getEstateRentTenancies({ estateId }: {
    estateId: GetEstateRentTenanciesPathParams["estateId"];
}, params?: GetEstateRentTenanciesQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetEstateRentTenanciesQueryResponse>["data"]> {
    const res = await client<GetEstateRentTenanciesQueryResponse>({ method: "get", url: `/api/rent-tenancies/estate/${estateId}`, params, ...options });
    return res.data;
}

 /**
 * @description Create rent tenancy
 * @link /api/rent-tenancies
 */
export async function saveRentTenancy(data?: SaveRentTenancyMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<SaveRentTenancyMutationResponse>["data"]> {
    const res = await client<SaveRentTenancyMutationResponse, SaveRentTenancyMutationRequest>({ method: "post", url: `/api/rent-tenancies`, data, ...options });
    return res.data;
}

 /**
 * @description Recalculate rent balance for one rent tenancy
 * @link /api/rent-tenancies/re-calculate-rent-balance
 */
export async function recalculateRentBalance(params: RecalculateRentBalanceQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<RecalculateRentBalanceMutationResponse>["data"]> {
    const res = await client<RecalculateRentBalanceMutationResponse>({ method: "post", url: `/api/rent-tenancies/re-calculate-rent-balance`, params, ...options });
    return res.data;
}

 /**
 * @description Sets the deposit return date
 * @link /api/rent-tenancies/:rentTenancyId/deposit-return-date
 */
export async function setDepositReturnDate({ rentTenancyId }: {
    rentTenancyId: SetDepositReturnDatePathParams["rentTenancyId"];
}, data?: SetDepositReturnDateMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<SetDepositReturnDateMutationResponse>["data"]> {
    const res = await client<SetDepositReturnDateMutationResponse, SetDepositReturnDateMutationRequest>({ method: "post", url: `/api/rent-tenancies/${rentTenancyId}/deposit-return-date`, data, ...options });
    return res.data;
}

 /**
 * @description Update dunning status
 * @link /api/rent-tenancies/:rentTenancyId/dunning-status
 */
export async function updateDunningStatus({ rentTenancyId }: {
    rentTenancyId: UpdateDunningStatusPathParams["rentTenancyId"];
}, data: UpdateDunningStatusMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<UpdateDunningStatusMutationResponse>["data"]> {
    const res = await client<UpdateDunningStatusMutationResponse, UpdateDunningStatusMutationRequest>({ method: "post", url: `/api/rent-tenancies/${rentTenancyId}/dunning-status`, data, ...options });
    return res.data;
}

 /**
 * @description Recalculates amount paid for rent deposit from transactions
 * @link /api/rent-tenancies/:rentTenancyId/recalculate-paid-deposit
 */
export async function recalculatePaidDeposit({ rentTenancyId }: {
    rentTenancyId: RecalculatePaidDepositPathParams["rentTenancyId"];
}, params?: RecalculatePaidDepositQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<RecalculatePaidDepositMutationResponse>["data"]> {
    const res = await client<RecalculatePaidDepositMutationResponse>({ method: "put", url: `/api/rent-tenancies/${rentTenancyId}/recalculate-paid-deposit`, params, ...options });
    return res.data;
}

 /**
 * @description Find rental contracts
 * @link /api/rental-contracts
 */
export async function findRenalContracts(params?: FindRenalContractsQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<FindRenalContractsQueryResponse>["data"]> {
    const res = await client<FindRenalContractsQueryResponse>({ method: "get", url: `/api/rental-contracts`, params, ...options });
    return res.data;
}

 /**
 * @description Upsert rental contract
 * @link /api/rental-contracts
 */
export async function upsertRentalContract(data: UpsertRentalContractMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<UpsertRentalContractMutationResponse>["data"]> {
    const res = await client<UpsertRentalContractMutationResponse, UpsertRentalContractMutationRequest>({ method: "post", url: `/api/rental-contracts`, data, ...options });
    return res.data;
}

 /**
 * @description Get rental contract by ID
 * @link /api/rental-contracts/:rentalContractId
 */
export async function getRenalContract({ rentalContractId }: {
    rentalContractId: GetRenalContractPathParams["rentalContractId"];
}, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetRenalContractQueryResponse>["data"]> {
    const res = await client<GetRenalContractQueryResponse>({ method: "get", url: `/api/rental-contracts/${rentalContractId}`, ...options });
    return res.data;
}

 /**
 * @description Create rents from rental contract
 * @link /api/rental-contracts/create-rents
 */
export async function createRentsFromRentalContract(data: CreateRentsFromRentalContractMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<CreateRentsFromRentalContractMutationResponse>["data"]> {
    const res = await client<CreateRentsFromRentalContractMutationResponse, CreateRentsFromRentalContractMutationRequest>({ method: "post", url: `/api/rental-contracts/create-rents`, data, ...options });
    return res.data;
}

 /**
 * @description Get all tenants
 * @link /api/tenants
 */
export async function getTenants(options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetTenantsQueryResponse>["data"]> {
    const res = await client<GetTenantsQueryResponse>({ method: "get", url: `/api/tenants`, ...options });
    return res.data;
}

 /**
 * @description Update tenant information
 * @link /api/tenants
 */
export async function updateTenantInformation(data: UpdateTenantInformationMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<UpdateTenantInformationMutationResponse>["data"]> {
    const res = await client<UpdateTenantInformationMutationResponse, UpdateTenantInformationMutationRequest>({ method: "put", url: `/api/tenants`, data, ...options });
    return res.data;
}

 /**
 * @description Get min/max dates of across investor shares data
 * @link /api/investor-share/min-max-date
 */
export async function getMinMaxDateOfMonthlyInvestorShare(options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetMinMaxDateOfMonthlyInvestorShareQueryResponse>["data"]> {
    const res = await client<GetMinMaxDateOfMonthlyInvestorShareQueryResponse>({ method: "get", url: `/api/investor-share/min-max-date`, ...options });
    return res.data;
}

 /**
 * @description Update monthly investor share operations
 * @link /api/monthly-investor-shares/:monthlyInvestorShareId/operations
 */
export async function updateMonthlyInvestorShareOperations({ monthlyInvestorShareId }: {
    monthlyInvestorShareId: UpdateMonthlyInvestorShareOperationsPathParams["monthlyInvestorShareId"];
}, data: UpdateMonthlyInvestorShareOperationsMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<UpdateMonthlyInvestorShareOperationsMutationResponse>["data"]> {
    const res = await client<UpdateMonthlyInvestorShareOperationsMutationResponse, UpdateMonthlyInvestorShareOperationsMutationRequest>({ method: "put", url: `/api/monthly-investor-shares/${monthlyInvestorShareId}/operations`, data, ...options });
    return res.data;
}

 /**
 * @description Update monthly investor share
 * @link /api/monthly-investor-shares/:monthlyInvestorShareId
 */
export async function updateMonthlyInvestorShare({ monthlyInvestorShareId }: {
    monthlyInvestorShareId: UpdateMonthlyInvestorSharePathParams["monthlyInvestorShareId"];
}, data?: UpdateMonthlyInvestorShareMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<UpdateMonthlyInvestorShareMutationResponse>["data"]> {
    const res = await client<UpdateMonthlyInvestorShareMutationResponse, UpdateMonthlyInvestorShareMutationRequest>({ method: "patch", url: `/api/monthly-investor-shares/${monthlyInvestorShareId}`, data, ...options });
    return res.data;
}

 /**
 * @description Export SEV payment
 * @link /api/investor-share/export-monthly-sev-payment
 */
 export async function exportMonthlySevPayment(data: ExportMonthlySevPaymentMutationRequest, params: ExportMonthlySevPaymentQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<ExportMonthlySevPaymentMutationResponse>["data"]> {
    const res = await client<ExportMonthlySevPaymentMutationResponse, ExportMonthlySevPaymentMutationRequest>({ method: "post", url: `/api/investor-share/export-monthly-sev-payment`, params, data, ...options });
    return res.data;
}

 /**
 * @description Get monthly rent guarantee date range
 * @link /api/monthly-rent-guarantees/date-range
 */
export async function getMonthlyRentGuaranteeRange(options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetMonthlyRentGuaranteeRangeQueryResponse>["data"]> {
    const res = await client<GetMonthlyRentGuaranteeRangeQueryResponse>({ method: "get", url: `/api/monthly-rent-guarantees/date-range`, ...options });
    return res.data;
}

 /**
 * @description Get monthly rent guarantee by ID
 * @link /api/monthly-rent-guarantees/:monthlyRentGuaranteeId
 */
export async function getMonthlyRentGuarantee({ monthlyRentGuaranteeId }: {
    monthlyRentGuaranteeId: GetMonthlyRentGuaranteePathParams["monthlyRentGuaranteeId"];
}, params?: GetMonthlyRentGuaranteeQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetMonthlyRentGuaranteeQueryResponse>["data"]> {
    const res = await client<GetMonthlyRentGuaranteeQueryResponse>({ method: "get", url: `/api/monthly-rent-guarantees/${monthlyRentGuaranteeId}`, params, ...options });
    return res.data;
}

 /**
 * @description Update monthly rent guarantee
 * @link /api/monthly-rent-guarantees/:monthlyRentGuaranteeId
 */
export async function updateMonthlyRentGuarantee({ monthlyRentGuaranteeId }: {
    monthlyRentGuaranteeId: UpdateMonthlyRentGuaranteePathParams["monthlyRentGuaranteeId"];
}, data?: UpdateMonthlyRentGuaranteeMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<UpdateMonthlyRentGuaranteeMutationResponse>["data"]> {
    const res = await client<UpdateMonthlyRentGuaranteeMutationResponse, UpdateMonthlyRentGuaranteeMutationRequest>({ method: "patch", url: `/api/monthly-rent-guarantees/${monthlyRentGuaranteeId}`, data, ...options });
    return res.data;
}

 /**
 * @description Generate rent guarantee in batch
 * @link /api/monthly-rent-guarantees/generate
 */
export async function rentGuaranteeBatchGenerate(data: RentGuaranteeBatchGenerateMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<RentGuaranteeBatchGenerateMutationResponse>["data"]> {
    const res = await client<RentGuaranteeBatchGenerateMutationResponse, RentGuaranteeBatchGenerateMutationRequest>({ method: "post", url: `/api/monthly-rent-guarantees/generate`, data, ...options });
    return res.data;
}

 /**
 * @description Find monthly rent guarantees
 * @link /api/monthly-rent-guarantees
 */
export async function findMonthlyRentGuarantees(params: FindMonthlyRentGuaranteesQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<FindMonthlyRentGuaranteesQueryResponse>["data"]> {
    const res = await client<FindMonthlyRentGuaranteesQueryResponse>({ method: "get", url: `/api/monthly-rent-guarantees`, params, ...options });
    return res.data;
}

 /**
 * @description Update a rent arrear notice
 * @link /api/rent-arrear-notice
 */
export async function updateArrearNotice(data: UpdateArrearNoticeMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<UpdateArrearNoticeMutationResponse>["data"]> {
    const res = await client<UpdateArrearNoticeMutationResponse, UpdateArrearNoticeMutationRequest>({ method: "put", url: `/api/rent-arrear-notice`, data, ...options });
    return res.data;
}

 /**
 * @description Delete a rent arrear notice
 * @link /api/rent-arrear-notice/:rentArrearNoticeId
 */
export async function deleteArrearNotice({ rentArrearNoticeId }: {
    rentArrearNoticeId: DeleteArrearNoticePathParams["rentArrearNoticeId"];
}, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<DeleteArrearNoticeMutationResponse>["data"]> {
    const res = await client<DeleteArrearNoticeMutationResponse>({ method: "delete", url: `/api/rent-arrear-notice/${rentArrearNoticeId}`, ...options });
    return res.data;
}

 /**
 * @description Generate arrear notices
 * @link /rent-arrear-notices/generate
 */
export async function generateArrearNotices(data: GenerateArrearNoticesMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GenerateArrearNoticesMutationResponse>["data"]> {
    const res = await client<GenerateArrearNoticesMutationResponse, GenerateArrearNoticesMutationRequest>({ method: "post", url: `/rent-arrear-notices/generate`, data, ...options });
    return res.data;
}

 /**
 * @link /stats
 */
export async function stats(params?: StatsQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<StatsQueryResponse>["data"]> {
    const res = await client<StatsQueryResponse>({ method: "get", url: `/stats`, params, ...options });
    return res.data;
}

 /**
 * @description Schedule contact invitation sending
 * @link /api/contacts/:contactId/invitation-request
 */
export async function sendContactInvitation({ contactId }: {
    contactId: SendContactInvitationPathParams["contactId"];
}, data: SendContactInvitationMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<SendContactInvitationMutationResponse>["data"]> {
    const res = await client<SendContactInvitationMutationResponse, SendContactInvitationMutationRequest>({ method: "post", url: `/api/contacts/${contactId}/invitation-request`, data, ...options });
    return res.data;
}

 /**
 * @description Get contacts Invitation Progress (Registered, Invited)
 * @link /api/contacts/invitation-progress
 */
export async function getPortalInvitationProgress(params: GetPortalInvitationProgressQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetPortalInvitationProgressQueryResponse>["data"]> {
    const res = await client<GetPortalInvitationProgressQueryResponse>({ method: "get", url: `/api/contacts/invitation-progress`, params, ...options });
    return res.data;
}

 /**
 * @description Get registered tenants at Livings
 * @link /api/registered-tenants
 */
export async function getRegisteredTenants(options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetRegisteredTenantsQueryResponse>["data"]> {
    const res = await client<GetRegisteredTenantsQueryResponse>({ method: "get", url: `/api/registered-tenants`, ...options });
    return res.data;
}

 /**
 * @description Update contact email
 * @link /api/contacts/:contactId/update-email
 */
export async function updateContactEmail({ contactId }: {
    contactId: UpdateContactEmailPathParams["contactId"];
}, data: UpdateContactEmailMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<UpdateContactEmailMutationResponse>["data"]> {
    const res = await client<UpdateContactEmailMutationResponse, UpdateContactEmailMutationRequest>({ method: "post", url: `/api/contacts/${contactId}/update-email`, data, ...options });
    return res.data;
}

 /**
 * @description Find investorAnnualSettlements
 * @link /api/investor-annual-settlement
 */
export async function findInvestorAnnualSettlements(params?: FindInvestorAnnualSettlementsQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<FindInvestorAnnualSettlementsQueryResponse>["data"]> {
    const res = await client<FindInvestorAnnualSettlementsQueryResponse>({ method: "get", url: `/api/investor-annual-settlement`, params, ...options });
    return res.data;
}

 /**
 * @description Creates a new investorAnnualSettlement
 * @link /api/investor-annual-settlement
 */
export async function createInvestorAnnualSettlement(data: CreateInvestorAnnualSettlementMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<CreateInvestorAnnualSettlementMutationResponse>["data"]> {
    const res = await client<CreateInvestorAnnualSettlementMutationResponse, CreateInvestorAnnualSettlementMutationRequest>({ method: "post", url: `/api/investor-annual-settlement`, data, ...options });
    return res.data;
}

 /**
 * @description Updates a investorAnnualSettlement
 * @link /api/investor-annual-settlement
 */
export async function updateInvestorAnnualSettlement(data: UpdateInvestorAnnualSettlementMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<UpdateInvestorAnnualSettlementMutationResponse>["data"]> {
    const res = await client<UpdateInvestorAnnualSettlementMutationResponse, UpdateInvestorAnnualSettlementMutationRequest>({ method: "put", url: `/api/investor-annual-settlement`, data, ...options });
    return res.data;
}

 /**
 * @description Deletes a investorAnnualSettlement
 * @link /api/investor-annual-settlement/:investorAnnualSettlementId
 */
export async function deleteInvestorAnnualSettlement({ investorAnnualSettlementId }: {
    investorAnnualSettlementId: DeleteInvestorAnnualSettlementPathParams["investorAnnualSettlementId"];
}, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<DeleteInvestorAnnualSettlementMutationResponse>["data"]> {
    const res = await client<DeleteInvestorAnnualSettlementMutationResponse>({ method: "delete", url: `/api/investor-annual-settlement/${investorAnnualSettlementId}`, ...options });
    return res.data;
}

 /**
 * @description Find investorYearlyPayouts
 * @link /api/investor-yearly-payout
 */
export async function findInvestorYearlyPayouts(params?: FindInvestorYearlyPayoutsQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<FindInvestorYearlyPayoutsQueryResponse>["data"]> {
    const res = await client<FindInvestorYearlyPayoutsQueryResponse>({ method: "get", url: `/api/investor-yearly-payout`, params, ...options });
    return res.data;
}

 /**
 * @description Creates a new investorYearlyPayout
 * @link /api/investor-yearly-payout
 */
export async function createInvestorYearlyPayout(data: CreateInvestorYearlyPayoutMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<CreateInvestorYearlyPayoutMutationResponse>["data"]> {
    const res = await client<CreateInvestorYearlyPayoutMutationResponse, CreateInvestorYearlyPayoutMutationRequest>({ method: "post", url: `/api/investor-yearly-payout`, data, ...options });
    return res.data;
}

 /**
 * @description Updates a investorYearlyPayout
 * @link /api/investor-yearly-payout
 */
export async function updateInvestorYearlyPayout(data: UpdateInvestorYearlyPayoutMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<UpdateInvestorYearlyPayoutMutationResponse>["data"]> {
    const res = await client<UpdateInvestorYearlyPayoutMutationResponse, UpdateInvestorYearlyPayoutMutationRequest>({ method: "put", url: `/api/investor-yearly-payout`, data, ...options });
    return res.data;
}

 /**
 * @description Deletes a investorYearlyPayout
 * @link /api/investor-yearly-payout/:investorYearlyPayoutId
 */
export async function deleteInvestorYearlyPayout({ investorYearlyPayoutId }: {
    investorYearlyPayoutId: DeleteInvestorYearlyPayoutPathParams["investorYearlyPayoutId"];
}, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<DeleteInvestorYearlyPayoutMutationResponse>["data"]> {
    const res = await client<DeleteInvestorYearlyPayoutMutationResponse>({ method: "delete", url: `/api/investor-yearly-payout/${investorYearlyPayoutId}`, ...options });
    return res.data;
}

 /**
 * @description Save a Nebenkostenabrechnung
 * @link /api/annual-service-charge-statement
 */
export async function saveAnnualServiceChargeStatement(data: SaveAnnualServiceChargeStatementMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<SaveAnnualServiceChargeStatementMutationResponse>["data"]> {
    const res = await client<SaveAnnualServiceChargeStatementMutationResponse, SaveAnnualServiceChargeStatementMutationRequest>({ method: "post", url: `/api/annual-service-charge-statement`, data, ...options });
    return res.data;
}

 /**
 * @description Find Annual Service Charges
 * @link /api/annual-service-charge-statement
 */
export async function findAnnualServiceChargeStatements(params?: FindAnnualServiceChargeStatementsQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<FindAnnualServiceChargeStatementsQueryResponse>["data"]> {
    const res = await client<FindAnnualServiceChargeStatementsQueryResponse>({ method: "get", url: `/api/annual-service-charge-statement`, params, ...options });
    return res.data;
}

 /**
 * @description Delete Nebenkostenabrechnung
 * @link /api/annual-service-charge-statement/:serviceChargeStatementId
 */
export async function deleteAnnualServiceChargeStatement({ serviceChargeStatementId }: {
    serviceChargeStatementId: DeleteAnnualServiceChargeStatementPathParams["serviceChargeStatementId"];
}, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<DeleteAnnualServiceChargeStatementMutationResponse>["data"]> {
    const res = await client<DeleteAnnualServiceChargeStatementMutationResponse>({ method: "delete", url: `/api/annual-service-charge-statement/${serviceChargeStatementId}`, ...options });
    return res.data;
}

 /**
 * @link /api/cronjob/:jobName
 */
export async function postApiCronjobJobname({ jobName }: {
    jobName: PostApiCronjobJobnamePathParams["jobName"];
}, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<PostApiCronjobJobnameMutationResponse>["data"]> {
    const res = await client<PostApiCronjobJobnameMutationResponse>({ method: "post", url: `/api/cronjob/${jobName}`, ...options });
    return res.data;
}

 export const operations = {
    "getConfig": {
        "path": "/config",
        "method": "get"
    },
    "saveRentingLead": {
        "path": "/api/renting-lead",
        "method": "post"
    },
    "getRentingLeadById": {
        "path": "/api/renting-lead/:rentingLeadId",
        "method": "get"
    },
    "deleteRentingLeadById": {
        "path": "/api/renting-lead/:rentingLeadId",
        "method": "delete"
    },
    "findRentingLeads": {
        "path": "/api/renting-lead/list",
        "method": "get"
    },
    "getRentingListing": {
        "path": "/api/renting-listing",
        "method": "get"
    },
    "createRentingListing": {
        "path": "/api/renting-listing",
        "method": "post"
    },
    "updateRentingListing": {
        "path": "/api/renting-listing/:rentingListingId",
        "method": "patch"
    },
    "deleteUnpublishedRentingListing": {
        "path": "/api/renting-listing/:rentingListingId",
        "method": "delete"
    },
    "deleteRentingListing": {
        "path": "/api/renting-listing/:rentingListingId/provider/:provider",
        "method": "delete"
    },
    "invokeRentingListingAction": {
        "path": "/api/renting-listing/:rentingListingId/providers/:provider/actions/:action",
        "method": "post"
    },
    "addAttachments": {
        "path": "/api/renting-listing/:rentingLeadId/attachments",
        "method": "post"
    },
    "getRentingListingAttachments": {
        "path": "/api/renting-listing/:rentingListingId/attachments",
        "method": "get"
    },
    "getRentingListingAttachmentsOrder": {
        "path": "/api/renting-listing/:rentingListingId/attachments-order",
        "method": "get"
    },
    "updateRentingListingAttachmentsOrder": {
        "path": "/api/renting-listing/:rentingListingId/attachments-order",
        "method": "put"
    },
    "updateRentingListingAttachment": {
        "path": "/api/renting-listing/:rentingListingId/attachments/:attachmentId",
        "method": "put"
    },
    "deleteRentingListingAttachment": {
        "path": "/api/renting-listing/:rentingListingId/attachments/:attachmentId",
        "method": "delete"
    },
    "exportRentingListingAsOpenImmo": {
        "path": "/api/renting-listing/:rentingListingId/export",
        "method": "get"
    },
    "getRentingDetailsOfEstate": {
        "path": "/api/renting/estate/:estateId",
        "method": "get"
    },
    "createEstateRentableUnit": {
        "path": "/api/estate-rentable-unit",
        "method": "post"
    },
    "createRoomRentableUnit": {
        "path": "/api/room-rentable-unit",
        "method": "post"
    },
    "createParkingRentableUnit": {
        "path": "/api/parking-rentable-unit",
        "method": "post"
    },
    "updateRentableUnit": {
        "path": "/api/rentable-unit",
        "method": "put"
    },
    "findRentableUnits": {
        "path": "/api/rentable-unit",
        "method": "get"
    },
    "getRentableUnitById": {
        "path": "/api/rentable-unit/:rentableUnitId",
        "method": "get"
    },
    "deleteRentableUnitById": {
        "path": "/api/rentable-unit/:rentableUnitId",
        "method": "delete"
    },
    "getRentableUnit": {
        "path": "/api/rentable-unit/one",
        "method": "get"
    },
    "deleteEstateRentableUnit": {
        "path": "/api/estate-rentable-unit/:estateId",
        "method": "delete"
    },
    "deleteRoomRentableUnit": {
        "path": "/api/room-rentable-unit/:estateElementId",
        "method": "delete"
    },
    "deleteParkingRentableUnit": {
        "path": "/api/parking-rentable-unit/:parkingSpaceId",
        "method": "delete"
    },
    "getEntitySetting": {
        "path": "/api/entity-setting",
        "method": "get"
    },
    "upsertEntitySetting": {
        "path": "/api/entity-setting",
        "method": "post"
    },
    "getEstateDetails": {
        "path": "/api/estate/:estateId/details",
        "method": "get"
    },
    "findInvestorInfos": {
        "path": "/api/investor-infos",
        "method": "get"
    },
    "createInvestorInfo": {
        "path": "/api/investor-infos",
        "method": "post"
    },
    "getActiveInvestorInfos": {
        "path": "/api/investor-infos/active",
        "method": "get"
    },
    "getInvestorInfo": {
        "path": "/api/investor-infos/:investorInfoId",
        "method": "get"
    },
    "updateInvestorInfo": {
        "path": "/api/investor-infos/:investorInfoId",
        "method": "put"
    },
    "initializeInvestorInfo": {
        "path": "/api/investor-infos/initialize",
        "method": "post"
    },
    "getTheoreticalPayoutBreakdown": {
        "path": "/api/investor-infos/:investorInfoId/theoretical-payout-breakdown",
        "method": "get"
    },
    "getLiquidityReserveBuildUpSample": {
        "path": "/api/investor-infos/:investorInfoId/liquidity-reserve-sample",
        "method": "get"
    },
    "updateInvestorInfoStartDateAndSpecialDeal": {
        "path": "/api/investor-infos/start-date-and-special-deal",
        "method": "patch"
    },
    "recalculateRentBalanceForInvestorInfo": {
        "path": "/api/investor-infos/re-calculate-rent-balance",
        "method": "post"
    },
    "recalculateRentBalanceForAllInvestorInfos": {
        "path": "/api/investor-infos/re-calculate-rent-balance/all",
        "method": "post"
    },
    "getInvestorData": {
        "path": "/api/investor-data",
        "method": "get"
    },
    "getActiveRentsAmounts": {
        "path": "/api/rents/active-amounts",
        "method": "get"
    },
    "getRent": {
        "path": "/api/rents/:rentId",
        "method": "get"
    },
    "createRent": {
        "path": "/api/rent-tenancies/:rentTenancyId/rents",
        "method": "post"
    },
    "updateRent": {
        "path": "/api/rent-tenancies/:rentTenancyId/rents/:rentId",
        "method": "put"
    },
    "deleteRent": {
        "path": "/api/rent-tenancies/:rentTenancyId/rents/:rentId",
        "method": "delete"
    },
    "updateRentBalanceAdjustments": {
        "path": "/api/rents/:rentId/balance-adjustments",
        "method": "put"
    },
    "getManyEstateActiveRentTenancies": {
        "path": "/api/rent-tenancies/estates",
        "method": "get"
    },
    "getActiveRentTenancies": {
        "path": "/api/rent-tenancies/active",
        "method": "get"
    },
    "getRentTenancy": {
        "path": "/api/rent-tenancies/:rentTenancyId",
        "method": "get"
    },
    "deleteRentTenancy": {
        "path": "/api/rent-tenancies/:rentTenancyId",
        "method": "delete"
    },
    "updateRentTenancy": {
        "path": "/api/rent-tenancies/:rentTenancyId",
        "method": "put"
    },
    "getEstateRentTenancies": {
        "path": "/api/rent-tenancies/estate/:estateId",
        "method": "get"
    },
    "saveRentTenancy": {
        "path": "/api/rent-tenancies",
        "method": "post"
    },
    "recalculateRentBalance": {
        "path": "/api/rent-tenancies/re-calculate-rent-balance",
        "method": "post"
    },
    "setDepositReturnDate": {
        "path": "/api/rent-tenancies/:rentTenancyId/deposit-return-date",
        "method": "post"
    },
    "updateDunningStatus": {
        "path": "/api/rent-tenancies/:rentTenancyId/dunning-status",
        "method": "post"
    },
    "recalculatePaidDeposit": {
        "path": "/api/rent-tenancies/:rentTenancyId/recalculate-paid-deposit",
        "method": "put"
    },
    "findRenalContracts": {
        "path": "/api/rental-contracts",
        "method": "get"
    },
    "upsertRentalContract": {
        "path": "/api/rental-contracts",
        "method": "post"
    },
    "getRenalContract": {
        "path": "/api/rental-contracts/:rentalContractId",
        "method": "get"
    },
    "createRentsFromRentalContract": {
        "path": "/api/rental-contracts/create-rents",
        "method": "post"
    },
    "getTenants": {
        "path": "/api/tenants",
        "method": "get"
    },
    "updateTenantInformation": {
        "path": "/api/tenants",
        "method": "put"
    },
    "getMinMaxDateOfMonthlyInvestorShare": {
        "path": "/api/investor-share/min-max-date",
        "method": "get"
    },
    "updateMonthlyInvestorShareOperations": {
        "path": "/api/monthly-investor-shares/:monthlyInvestorShareId/operations",
        "method": "put"
    },
    "updateMonthlyInvestorShare": {
        "path": "/api/monthly-investor-shares/:monthlyInvestorShareId",
        "method": "patch"
    },
    "exportMonthlySevPayment": {
        "path": "/api/investor-share/export-monthly-sev-payment",
        "method": "post"
    },
    "getMonthlyRentGuaranteeRange": {
        "path": "/api/monthly-rent-guarantees/date-range",
        "method": "get"
    },
    "getMonthlyRentGuarantee": {
        "path": "/api/monthly-rent-guarantees/:monthlyRentGuaranteeId",
        "method": "get"
    },
    "updateMonthlyRentGuarantee": {
        "path": "/api/monthly-rent-guarantees/:monthlyRentGuaranteeId",
        "method": "patch"
    },
    "rentGuaranteeBatchGenerate": {
        "path": "/api/monthly-rent-guarantees/generate",
        "method": "post"
    },
    "findMonthlyRentGuarantees": {
        "path": "/api/monthly-rent-guarantees",
        "method": "get"
    },
    "updateArrearNotice": {
        "path": "/api/rent-arrear-notice",
        "method": "put"
    },
    "deleteArrearNotice": {
        "path": "/api/rent-arrear-notice/:rentArrearNoticeId",
        "method": "delete"
    },
    "generateArrearNotices": {
        "path": "/rent-arrear-notices/generate",
        "method": "post"
    },
    "stats": {
        "path": "/stats",
        "method": "get"
    },
    "sendContactInvitation": {
        "path": "/api/contacts/:contactId/invitation-request",
        "method": "post"
    },
    "getPortalInvitationProgress": {
        "path": "/api/contacts/invitation-progress",
        "method": "get"
    },
    "getRegisteredTenants": {
        "path": "/api/registered-tenants",
        "method": "get"
    },
    "updateContactEmail": {
        "path": "/api/contacts/:contactId/update-email",
        "method": "post"
    },
    "findInvestorAnnualSettlements": {
        "path": "/api/investor-annual-settlement",
        "method": "get"
    },
    "createInvestorAnnualSettlement": {
        "path": "/api/investor-annual-settlement",
        "method": "post"
    },
    "updateInvestorAnnualSettlement": {
        "path": "/api/investor-annual-settlement",
        "method": "put"
    },
    "deleteInvestorAnnualSettlement": {
        "path": "/api/investor-annual-settlement/:investorAnnualSettlementId",
        "method": "delete"
    },
    "findInvestorYearlyPayouts": {
        "path": "/api/investor-yearly-payout",
        "method": "get"
    },
    "createInvestorYearlyPayout": {
        "path": "/api/investor-yearly-payout",
        "method": "post"
    },
    "updateInvestorYearlyPayout": {
        "path": "/api/investor-yearly-payout",
        "method": "put"
    },
    "deleteInvestorYearlyPayout": {
        "path": "/api/investor-yearly-payout/:investorYearlyPayoutId",
        "method": "delete"
    },
    "saveAnnualServiceChargeStatement": {
        "path": "/api/annual-service-charge-statement",
        "method": "post"
    },
    "findAnnualServiceChargeStatements": {
        "path": "/api/annual-service-charge-statement",
        "method": "get"
    },
    "deleteAnnualServiceChargeStatement": {
        "path": "/api/annual-service-charge-statement/:serviceChargeStatementId",
        "method": "delete"
    },
    "post_api-cronjob-jobname": {
        "path": "/api/cronjob/:jobName",
        "method": "post"
    }
} as const;